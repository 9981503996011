import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';

export const BaseTabHeaderButton = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid transparent;
  border-bottom: 3px solid transparent;
  color: ${nafColor.neutral.neutral5};
  flex-shrink: 0;
  flex-basis: max-content;

  &:hover {
    color: ${nafColor.signature.black};
    border-bottom-color: ${nafColor.neutral.neutral5};
    outline: 0;
    letter-spacing: inherit;
  }

  ${({ $active }) =>
    $active
      ? css`
          color: ${nafColor.signature.black};
          font-weight: 600;
          border-bottom-color: ${nafColor.primary.kart};
          letter-spacing: inherit;
        `
      : ''}

  &:focus:not(:disabled)[type='button'] {
    color: ${nafColor.signature.black};
    font-weight: 600;
    border-color: ${nafColor.neutral.neutral5};
    border-radius: 2px;
    letter-spacing: inherit;
    outline: none;
  }
`;

export const TabHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
  column-gap: ${spacing.space24};
  height: 50px;
  margin-bottom: -1px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px; /* Fade size */
  }
`;

export const HeaderBorder = styled.div`
  height: 49px;
  border-bottom: 1px solid ${nafColor.neutral.neutral2};
  min-width: max-content;
  width: 100%;
`;

export const ScrollContainer = styled.div`
  display: flex;
  margin: 0 -${spacing.space40};
  padding: 0 ${spacing.space40};
  box-sizing: border-box;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100vw;
    margin: 0 -${spacing.space24};
    padding: 0 ${spacing.space24};
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: ${spacing.space40} 0 ${spacing.space64} 0;
`;
