import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { NAFGTMEvent } from '../../../../../../types/GTM/NAFGTMEvent';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { SectionBlockType } from '../../../../../../types/CategoryAppType';
import ErrorBoundary from '../../../error-component/ErrorBoundary';
import { SectionBlocks } from '../../index';
import useSelector from '../../../../redux/typedHooks';
import { BaseTabHeaderButton, HeaderBorder, ScrollContainer, SectionContainer, TabHeader } from './Styles';
import { getURLParams } from '../../../../lib/getUrlParams';
import { updateUrlQueryValues } from '../../../../utils/updateUrlQueryValues';

interface TabView {
  title: string;
  slug: string;
  sections: SectionBlockType[];
}

interface Props {
  block: {
    key: string;
    type: string;
    tabViews: TabView[];
  };
}

export const TabBlock = ({ block: { tabViews } }: Props) => {
  const [selectedTabSlug, setSelectedTabSlug] = useState(tabViews[0]?.slug);
  const paramName = `tabView`;

  const tabViewMap = useMemo(
    () =>
      tabViews.reduce((acc: Record<TabView['slug'], TabView>, cur) => {
        acc[cur.slug] = cur;
        return acc;
      }, {}),
    [tabViews],
  );

  useEffect(() => {
    const params = getURLParams();
    if (
      typeof params[paramName] !== 'undefined' &&
      setSelectedTabSlug &&
      paramName &&
      typeof params[paramName] === 'string'
    ) {
      setSelectedTabSlug(params[paramName] as string);
    }
  }, [setSelectedTabSlug, paramName]);

  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement>, tabSlug: string) => {
    e.preventDefault();

    if (dataLayer) {
      dataLayer.push({
        event: NAFGTMEvent.tabNavigation,
        tabNavigation: {
          from: tabViews[selectedTabSlug]?.title || 'UNDEFINED',
          to: tabViews[tabSlug]?.title || 'ERROR',
        },
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      });
    }

    setSelectedTabSlug(tabSlug);
    updateUrlQueryValues.updateGenericParam(tabSlug, paramName);
  };

  return (
    <div>
      <ScrollContainer>
        <HeaderBorder>
          <TabHeader role="tablist">
            {tabViews.map((tab) => (
              <BaseTabHeaderButton
                key={tab.slug}
                $active={selectedTabSlug === tab.slug}
                type="button"
                onClick={(e) => handleHeaderClick(e, tab.slug)}
                role="tab"
                aria-selected={selectedTabSlug === tab.slug}
                aria-controls={tab.title}
                id={tab.slug}
              >
                {tab.title}
              </BaseTabHeaderButton>
            ))}
          </TabHeader>
        </HeaderBorder>
      </ScrollContainer>
      <ErrorBoundary>
        <SectionContainer role="tabpanel" aria-labelledby={`tab-${tabViews[selectedTabSlug]?.title}`}>
          <SectionBlocks content={tabViewMap[selectedTabSlug]?.sections || []} />
        </SectionContainer>
      </ErrorBoundary>
    </div>
  );
};
